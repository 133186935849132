import React from "react"
import styled from 'styled-components';

const StickyWrapper = styled.div`
  position: sticky;
  top: calc(180px);

  @media (min-width: 992px) {
    min-height: 480px;
    transition-duration: 500ms;
  }
  @media (max-width: 992px) {
    margin-bottom: 25px;
    width: 100%;
  }
`;
const FlexContainer = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.75rem;
  padding-left: 40px;
  margin: 0 auto;

  @media (min-width: 768px) {
    min-width: 450px;
  }


  @media only screen and (min-width: 0em) {
    {
      flex-basis: 100%;
      max-width: 100%;
      display: block;
    }
  }

  @media only screen and (min-width: 62em) {
    {

      display: block;
    }
  }
  @media (max-width: 992px) {
    {
      width: 100%;
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }
  }
`;

const SummaryCardContent = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 10px;
  overflow: hidden;
  position: relative;
  height: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 24px;
`;

const Headline = styled.h2`
  font-size: 32px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.3;
  margin: 0px;
  color: rgb(0, 79, 239);
`;

const SubHeadline = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
`;

const DataList = styled.dl`
  width: 100%;
  margin-bottom: 10px;

`;

const DataTerm = styled.dt`
  font-weight: bold;
`;
const ListItem = styled.div`
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  padding-bottom: 8px;
  justify-content: space-between;
  
  p {
    font-size: 16px;
    color: rgb(45, 47, 61);
    font-weight: 500;
    line-height: 1.6;
    margin: 0;
  }
`;

const SeparatorLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-top: 1px solid rgb(232, 232, 232);
`;

const DataDescription = styled.dd`
  margin-left: 0;
`;

const BlueButton = styled.button`
  background-color: rgb(0, 79, 239);
  border-radius: 3px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline;
  font-size: 14px;
  font-weight: 500;
  min-width: 118px;
  padding: 12px 24px;
  pointer-events: auto;
  text-decoration: none;
  text-align: center;
  background-position: center center;
  transition: background 0.8s ease 0s;
  width: 100%;
  position: relative;
  
  :hover
    {
      background: rgb(25, 96, 240) radial-gradient(circle, transparent 1%, rgb(25, 96, 240) 1%) center center / 15000%;
    }
    :active
      {
        background-color: rgb(0, 79, 239);
        background-size: 100%;
        transition: background 0s ease 0s;
      }
`;

const CourseSummary = props => {

  const handleButtonClick = (e) => {
    e.preventDefault();
    const anchor = document.getElementById(props.currentlang === "de" ? 'kursanfrage' : 'course-inquiry');
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <StickyWrapper>
      <FlexContainer>
      {props.currentlang ==="de" &&
        <SummaryCardContent>
          <Headline>Kursübersicht</Headline>
            {(props.selectedInput && (props.decoursetype ==="Abendkurs" || props.decoursetype ==="Intensivkurs" )) &&
              <SubHeadline>{props.langDe} {props.decoursetype} ({props.selectedInput})</SubHeadline>
            }
            {(props.selectedInput && props.decoursetype ==="Live Online") &&
              <SubHeadline>{props.decoursetype} {props.langDe}kurs ({props.selectedInput})</SubHeadline>
            }
            <DataList>
              {props.selectedDate &&
                <ListItem>
                  <DataTerm>
                    <p>Kursfokus</p>
                    </DataTerm>
                  <DataDescription>{props.focus_de}</DataDescription>
                </ListItem>
              }
              <ListItem>
                <DataTerm>
                  <p>Unterrichtseinheiten</p>
                  </DataTerm>
                {props.decoursetype === "Live Online" &&
                  <DataDescription>40</DataDescription>
                }
                {props.decoursetype === "Abendkurs" &&
                  <DataDescription>60</DataDescription>
                }
                {props.decoursetype === "Intensivkurs" &&
                  <DataDescription>80</DataDescription>
                }
              </ListItem>
              {props.selectedDate &&
                <ListItem>
                  <DataTerm>
                    <p>Dauer</p>
                    </DataTerm>
                  <DataDescription>{props.length_de}</DataDescription>
                </ListItem>
              }
              <ListItem>
                <DataTerm>
                  <p>Teilnehmer</p>
                </DataTerm>
                {props.decoursetype === "Abendkurs" &&
                <DataDescription>3-6</DataDescription>
                }
                {props.decoursetype === "Live Online" &&
                <DataDescription>3-6</DataDescription>
                }
                {props.decoursetype === "Intensivkurs" &&
                  <DataDescription>4-8</DataDescription>
                }
              </ListItem>
              {props.selectedDate &&
                <React.Fragment>
                  <ListItem>
                    <DataTerm><p>Startdatum</p></DataTerm>
                    <DataDescription>{props.selectedDate}</DataDescription>
                  </ListItem>
                  <ListItem>
                    <DataTerm><p>Zeitplan</p></DataTerm>
                    <DataDescription>{props.schedule_de}</DataDescription>
                  </ListItem>
                  {props.decoursetype !=="Intensivkurs" &&
                    <ListItem>
                      <DataTerm><p>pro Monat</p></DataTerm>
                      <DataDescription>{props.costs}</DataDescription>
                    </ListItem>
                  }
                </React.Fragment>   
              }
            </DataList>
          <SeparatorLine></SeparatorLine>
          <a href="#" onClick={handleButtonClick}>
            <BlueButton>Kursanfrage senden</BlueButton>
          </a>
        </SummaryCardContent> 
        }
        {props.currentlang ==="en" &&
          <SummaryCardContent>
          <Headline>Course Overview</Headline>
            {(props.selectedInput && (props.encoursetype ==="Evening Course" || props.decoursetype ==="Intensive Course" )) &&
              <SubHeadline>{props.selectedLanguage} {props.encoursetype} ({props.selectedInput})</SubHeadline>
            }
            {(props.selectedInput && props.encoursetype ==="Live Online") &&
              <SubHeadline>{props.encoursetype} {props.selectedLanguage} Course ({props.selectedInput})</SubHeadline>
            }
            <DataList>
            {props.selectedDate &&
                <ListItem>
                  <DataTerm>
                    <p>Course Focus</p>
                    </DataTerm>
                  <DataDescription>{props.focus_en}</DataDescription>
                </ListItem>
              }
              <ListItem>
                <DataTerm>
                  <p>Lesson Units</p>
                  </DataTerm>
                {props.encoursetype === "Live Online" &&
                  <DataDescription>40</DataDescription>
                }
                {props.encoursetype === "Evening Course" &&
                  <DataDescription>60</DataDescription>
                }
                {props.encoursetype === "Intensive Course" &&
                  <DataDescription>80</DataDescription>
                }
              </ListItem>
              {props.selectedDate &&
                <ListItem>
                  <DataTerm>
                    <p>Course Length</p>
                    </DataTerm>
                  <DataDescription>{props.length_en}</DataDescription>
                </ListItem>
              }
              <ListItem>
                <DataTerm>
                  <p>Participants</p>
                </DataTerm>
                {props.encoursetype === "Evening Course" &&
                <DataDescription>3-6</DataDescription>
                }
                {props.encoursetype === "Live Online" &&
                <DataDescription>3-6</DataDescription>
                }
                {props.encoursetype === "Intensive Course" &&
                  <DataDescription>4-8</DataDescription>
                }
              </ListItem>
              {props.selectedDate &&
                <React.Fragment>
                  <ListItem>
                    <DataTerm><p>Start Date</p></DataTerm>
                    <DataDescription>{props.selectedDate}</DataDescription>
                  </ListItem>
                  <ListItem>
                    <DataTerm><p>Schedule</p></DataTerm>
                    <DataDescription>{props.schedule_en}</DataDescription>
                  </ListItem>
                  {props.encoursetype !=="Intensive Course" &&
                    <ListItem>
                      <DataTerm><p>Monthly</p></DataTerm>
                      <DataDescription>{props.costs}</DataDescription>
                    </ListItem>
                  }
                </React.Fragment>   
              }
            </DataList>
          <SeparatorLine></SeparatorLine>
          <a href="#" onClick={handleButtonClick}>
            <BlueButton>Send Inquiry</BlueButton>
          </a>
        </SummaryCardContent> 
        }
      </FlexContainer>        
    </StickyWrapper>
  );
};

export default CourseSummary;
